<template>
  <div class="case">
    <el-row>
      <div>当前案例：{{ associationTitle }}</div>
      <div style="width: 100%; padding: 12px 0">
        <el-button size="small" @click="$router.back()">返回</el-button>
        <!-- <el-button size="small" @click="this.$parent.step = 1">返回</el-button> -->
        <el-button size="small" @click="addDepart()" type="primary">添加部件</el-button>
        <el-button size="small" @click="delDepart()" type="primary">移除</el-button>
        已选{{ selectList.length }}项
      </div>
      <div style="width: 100%">
        <el-table
          :header-row-style="{
            backgroundColor: '#F2F2F2',
            textAlign: 'center',
          }"
          :cell-style="{ textAlign: 'center', cursor: 'pointer' }"
          @row-click="cellClick"
          :data="tableData"
          border
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
            width="60"
            label="序号"
            type="index"
            :index="indexMethod"
          ></el-table-column>
          <!-- <el-table-column prop="c_model_number" width="180" label="编号"></el-table-column> -->
          <el-table-column prop="number" width="180" label="编号"></el-table-column>
          <!-- <el-table-column prop="c_master_map" label="图片">
                        <template #default="scope">
                            <el-image
                                style="width: 45px; height: 45px"
                                :src="scope.row.c_master_map"
                                :preview-src-list="[scope.row.c_master_map]"
                            >
                            </el-image>
                        </template>
                    </el-table-column> -->
          <el-table-column prop="c_auxiliary_chart" label="图片">
            <template #default="scope">
              <el-image
                style="width: 45px; height: 45px"
                :src="scope.row.c_auxiliary_chart"
                :preview-src-list="[scope.row.c_auxiliary_chart]"
              ></el-image>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="c_name" width="220" label="名称">
                        <template #default="scope">
                            <el-button type="text">{{ scope.row.c_name }}</el-button>
                        </template>
                    </el-table-column> -->
          <el-table-column prop="model_name" width="220" label="名称">
            <template #default="scope">
              <el-button type="text">{{ scope.row.model_name }}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="category_name" width="100" label="所属分类"></el-table-column>
          <!-- <el-table-column prop="c_materials" label="材质"></el-table-column> -->
          <el-table-column prop="material_name" label="材质"></el-table-column>
          <!-- <el-table-column prop="c_size" label="尺寸"></el-table-column> -->
          <el-table-column prop="format_size" label="尺寸"></el-table-column>
          <!-- <el-table-column prop="belonging_to" width="80" label="尺寸">
                        <template #default="scope">
                            <div v-if="scope.row.belonging_to == 1">硬装</div>
                            <div v-if="scope.row.belonging_to == 2">软装</div>
                        </template>
                    </el-table-column> -->
          <el-table-column fixed="right" label="展示排序" width="180">
            <template #default="scope">
              <el-input @change="changeSort(scope.row)" v-model="scope.row.sort"></el-input>
            </template>
          </el-table-column>
          <el-table-column fixed="right" width="100" label="操作">
            <template #default="scope">
              <el-button type="text" @click="depart(scope.row)">关联标签</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: right; margin-top: 25px">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.page"
            :page-sizes="[10, 15, 30, 35]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.totalItems"
          ></el-pagination>
        </div>
      </div>

      <el-dialog
        title="关联标签"
        v-model="dialogFormVisible1"
        :visible="dialogFormVisible1"
        width="650px"
        top="50px"
        destroy-on-close
        @close="closeDialod1"
        center
      >
        <div>
          <div style="text-align: left; line-height: 55px">当前构件：{{ c_name }}</div>
          <el-table
            ref="multipleTableRef"
            :header-cell-style="{ background: '#F2F2F2', textAlign: 'center' }"
            :cell-style="{ textAlign: 'center', cursor: 'pointer' }"
            @row-click="cellClick"
            :data="tableData2"
            border
            style="width: 100%"
            @selection-change="handleSelectionChange2"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
              width="60"
              label="序号"
              type="index"
              :index="indexMethod1"
            ></el-table-column>
            <el-table-column prop="name" label="标签信息"></el-table-column>
          </el-table>
          <!-- <div style="text-align: right; margin-top: 25px">
                    <el-pagination
                        background
                        @size-change="handleSizeChange2"
                        @current-change="handleCurrentChange2"
                        :current-page="page2.page"
                        :page-sizes="[10, 15, 30, 35]"
                        :page-size="page2.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="page2.totalItems"
                    >
                    </el-pagination>
                </div> -->
          <div style="text-align: center; margin-top: 25px">
            <el-button size="medium" @click="saveAssDepart()" type="primary">确定</el-button>
            <el-button size="medium" @click="closeDialod1()">取消</el-button>
          </div>
        </div>
      </el-dialog>

      <el-dialog
        title="添加部件"
        :visible="dialogFormVisible"
        width="850px"
        top="50px"
        destroy-on-close
        @close="closeDialod"
        center
      >
        <div style="width: 100%">
          <el-form size="small" :inline="true" :model="formInline" class="demo-form-inline">
            <!-- <el-form-item>
              <el-select
                clearable
                @change="changeclassifys()"
                style="width: 200px"
                v-model="formInline.classifys_id"
                placeholder="请选择分类"
              >
                <el-option
                  v-for="item in classifys"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                clearable
                style="width: 200px"
                v-model="formInline.child_id"
                placeholder="全部"
              >
                <el-option
                  v-for="item in classifysChildren"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item>
              <el-input
                style="width: 200px"
                v-model="formInline.c_search"
                placeholder="请输入关键词"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="addDepart">查询</el-button>
            </el-form-item>
          </el-form>
        </div>

        <div style="width: 100%">
          <el-table
            :header-cell-style="{ background: '#F2F2F2', textAlign: 'center' }"
            :cell-style="{ textAlign: 'center', cursor: 'pointer' }"
            @row-click="cellClick"
            :data="tableData1"
            border
            style="width: 100%"
            @selection-change="handleSelectionChange1"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <!-- <el-table-column prop="c_model_number" width="180" label="编号"></el-table-column> -->
            <el-table-column prop="number" width="180" label="编号"></el-table-column>
            <!-- <el-table-column prop="c_name" width="100" label="名称"></el-table-column> -->
            <el-table-column prop="model_name" width="100" label="名称"></el-table-column>
            <!-- <el-table-column prop="c_materials" label="材质"></el-table-column> -->
            <el-table-column prop="material_name" label="材质"></el-table-column>
            <el-table-column prop="c_size" width="80" label="尺寸">
              <template #default="scope">
                <!-- <div>{{ scope.row.c_size }}</div> -->
                <div>{{ scope.row.format_size }}</div>
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: right; margin-top: 25px">
            <el-pagination
              background
              @size-change="handleSizeChange1"
              @current-change="handleCurrentChange1"
              :current-page="page1.page"
              :page-sizes="[10, 15, 30, 35]"
              :page-size="page1.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="page1.totalItems"
            ></el-pagination>
          </div>
          <div style="text-align: center; padding: 25px 0 15px">
            已选{{ selectDepart.length }}项
            <el-button size="small" @click="submit()" type="primary">确定</el-button>
            <!-- <el-button size="small">取消</el-button> -->
          </div>
        </div>
      </el-dialog>
    </el-row>
  </div>
</template>
<script>
// import { getList, edit, del } from '../common/js/member';
import {
  associationcomponentlist,
  saveADepart,
  componentlist,
  departLabel,
  deleleDepart,
  changeSort,
  submitDepart,
} from '@/common/list.js';

export default {
  name: 'PartList',
  props: {
    // associationId: {
    //   type: Number,
    //   default: null,
    // },
    // associationTitle: {
    //   type: String,
    //   default: null,
    // },
  },
  data() {
    return {
      classifys: [],
      classifysChildren: [],
      setupBelonging_to: [],
      imageUrl: '',
      associationId: '',
      associationTitle: '',
      selectArr: {
        setup_id1: '',
        material_id: '', //二级构件关联id
      },
      rules: {
        name: [{ required: true, message: '请输入案例名称', trigger: 'blur' }],
        style: [{ required: true, message: '请选择案例风格', trigger: 'change' }],
        intro: [{ required: true, message: '请输入案例介绍', trigger: 'blur' }],
        c_product_model: [{ required: true, message: '请上传案例图片', trigger: 'blur' }],
      },
      statusArray: [],
      styleArray: [],
      formInline: {
        classifys_id: '',
        child_id: '',
        c_search: '',
      },
      tableData: [],
      tableData1: [],
      tableData2: [],
      // 权限操作集合
      permissionsAction: {},
      page: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
      },
      page1: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
      },
      page2: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
      },
      ruleForm: {
        id: '',
        class: '',
        name: '',
        img: '',
        tagArr: [],
      },
      dialogFormVisible: false,
      dialogFormVisible1: false,
      inputVisible: false,
      inputValue: '',
      selectDepart: [],
      selectList: [],
      c_name: '',
      ac_id: '',
      selectAssDepart: [],
    };
  },
  created() {
    console.log(this.$route.query);
    this.associationId = this.$route.query.id;
    this.associationTitle = this.$route.query.title;
  },
  mounted() {
    this.$nextTick(() => {
      if (this.associationId) {
        this.init();
      }
    });
  },
  methods: {
    saveAssDepart() {
      let dt = {
        ac_id: this.ac_id,
        add_label: this.selectAssDepart,
      };
      // console.log(this.selectAssDepart);

      saveADepart(dt).then((res) => {
        this.$message.success(res.msg);
        this.closeDialod1();
      });
    },
    closeDialod1() {
      this.c_name = '';
      this.ac_id = '';
      this.dialogFormVisible1 = false;
      this.tableData2 = [];
    },
    depart(row) {
      // console.log(this.associationId);
      // console.log(row);
      let dt = {
        case_id: this.associationId,
        // ac_id: row.id
        ac_id: row.ac_id,
      };
      // this.c_name = row.c_name;
      // this.ac_id = row.id;
      this.c_name = row.model_name;
      this.ac_id = row.ac_id;
      departLabel(dt).then((res) => {
        // console.log(res);
        this.tableData2 = res.result.label;
        this.dialogFormVisible1 = true;
        this.$nextTick(() => {
          for (var item in this.tableData2) {
            if (this.tableData2[item].is_select == 1) {
              this.$refs.multipleTableRef.toggleRowSelection(this.tableData2[item]);
            }
          }
        });
        // console.log(this.tableData2);
      });
    },
    delDepart() {
      // console.log(this.selectList);
      if (this.selectList.length) {
        this.$confirm('确定移除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          let dt = {};
          dt.id = this.selectList;
          deleleDepart(dt).then((res) => {
            this.$message.success(res.msg);
            this.init();
          });
        });
      }
    },
    changeSort(row) {
      // console.log(row);
      if (row.sort) {
        let dt = {
          // id: row.id,
          id: row.ac_id,
          sort: row.sort,
        };
        changeSort(dt).then((res) => {
          this.$message.success(res.msg);
          this.init();
        });
      }
    },
    changeclassifys() {
      for (var i = 0; i < this.classifys.length; i++) {
        if (this.formInline.classifys_id == this.classifys[i].id) {
          this.classifysChildren = this.classifys[i].children;
        }
      }
    },
    addDepart() {
      let dt = {
        id: this.associationId,
        page: this.page1.page,
        limit: this.page1.pageSize,
        search_keyword: this.formInline.c_search,
        category_pid: this.formInline.classifys_id,
        category_id: this.formInline.child_id,
      };
      // console.log(dt);
      componentlist(dt).then((res) => {
        // console.log(res);
        this.page1.totalItems = res.result.total;
        this.tableData1 = res.result.data;
        this.classifys = res.result.classifys;
        this.dialogFormVisible = true;
      });
    },
    handleSelectionChange(val) {
      this.selectList = [];
      for (var a = 0; a < val.length; a++) {
        // console.log(val[a]);
        this.selectList.push(val[a].ac_id);
      }
    },
    handleSelectionChange2(val) {
      // console.log(val);
      let arr = [];
      for (var r = 0; r < val.length; r++) {
        arr.push({
          case_file_id: val[r].case_file_id,
          label_key: val[r].label_key,
        });
      }
      this.selectAssDepart = arr;
      // console.log(this.selectAssDepart);
    },
    handleSelectionChange1(val) {
      // console.log(val);
      this.selectDepart = [];
      for (var v = 0; v < val.length; v++) {
        //     // this.selectDepart.push(val[v].c_id);

        // let arr = [];
        // arr.push(['r_id'] + '=>' + val[v].r_id + ',' + ['m_id'] + '=>' + val[v].m_id);
        // arr.push(['m_id'] + '=>' + val[v].m_id);

        //     // arr.push('r_id');
        //     // arr.push(val[v].r_id);
        //     // arr.push('m_id');
        //     // arr.push(val[v].m_id);
        //     // if (val[v]) {
        //     //     for (let [a, b] of dt.entries()) {
        //     //         console.log(a + '=>' + b);
        //     //     }
        //     // }

        // console.log(arr);
        // this.selectDepart.push(['r_id'] + '=>' + val[v].r_id + ',' + ['m_id'] + '=>' + val[v].m_id);
        // this.selectDepart.push({['r_id'] : val[v].r_id} ,{['m_id']: val[v].m_id)};
        this.selectDepart.push({
          ['r_id']: val[v].r_id,
          ['m_id']: val[v].m_id,
        });

        // this.selectDepart.push(arr);
        //     // this.selectDepart.push(val[v].r_id);
        //     // this.selectDepart.push(val[v].m_id);
      }

      // console.log(this.selectDepart);
    },
    handleClose(tag) {
      this.ruleForm.tagArr.splice(this.ruleForm.tagArr.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.InputRef.focus();
      });
    },
    handleInputConfirm() {
      if (this.inputValue) {
        this.ruleForm.tagArr.push(this.inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    handleUploadArtifactsMainPicture(file) {
      const isType = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isType) {
        this.$messageBox.error('上传图片只能是 jpg/png 格式!');
        this.ruleForm.img = '';
        this.imageUrl = '';
      } else if (!isLt10M) {
        this.$messageBox.error('上传图片大小不能超过 10MB!');
        this.ruleForm.img = '';
        this.imageUrl = '';
      } else {
        this.ruleForm.img = file;
        this.imageUrl = URL.createObjectURL(file.raw);
      }
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.ruleForm.img = file;
    },
    beforeAvatarUpload(file) {
      const isType =
        file.raw.type === 'image/jpg' ||
        file.raw.type === 'image/jpeg' ||
        file.raw.type === 'image/png';
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isType) {
        this.$message.error('上传图片只能是 jpg/png 格式!');
        this.ruleForm.img = '';
        this.imageUrl = '';
      } else if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!');
        this.ruleForm.img = '';
        this.imageUrl = '';
      } else {
        this.ruleForm.img = file;
        this.imageUrl = URL.createObjectURL(file.raw);
      }
    },
    cellClick(row, column) {
      if (column.no != 6 && column.no != 8) {
        this.detailOne(row);
      }
    },
    handleRemove(file, fileList) {
      if (!file.raw) {
        this.deleteImgArr.push(file.url1);
      }
      this.ruleForm.c_product_model = fileList;
      this.$refs['ruleForm'].validateField('c_product_model');
    },
    handPreview(file, fileList) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!');
        fileList.splice(fileList.length - 1, 1);
      }
      this.ruleForm.c_product_model.push(file);
      this.$refs['ruleForm'].validateField('c_product_model');
    },
    handleRemove1(file, fileList) {
      if (!file.raw) {
        this.deleteImgArr.push(file.url1);
      }
      this.ruleForm.c_product_model = fileList;
      this.$refs['ruleForm'].validateField('c_product_model');
    },
    handPreview1(file, fileList) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!');
        fileList.splice(fileList.length - 1, 1);
      }
      this.ruleForm.c_product_model.push(file);
      this.$refs['ruleForm'].validateField('c_product_model');
    },
    detailOne(row) {
      this.$emit('detailOne', row);
    },
    submit() {
      let dt = {
        id: this.associationId,
        // component_id: this.selectDepart
        model_arr: this.selectDepart,
      };
      submitDepart(dt).then((res) => {
        this.$message.success(res.msg);
        this.closeDialod();
      });
    },
    closeDialod() {
      this.formInline = {
        classifys_id: '',
        child_id: '',
        c_search: '',
      };
      this.classifys = [];
      this.classifysChildren = [];
      this.dialogFormVisible = false;
      this.init();
    },
    edit(row) {
      if (row) {
        this.ruleForm = {
          id: row.id,
          class: row.belonging_to,
          name: row.setup_name,
          img: row.picture,
        };
        this.imageUrl = row.picture;
      }
      this.dialogFormVisible = true;
    },
    del(row, type) {
      let title = '';
      if (type == 1) {
        title = '确认停用？';
      } else if (type == 2) {
        title = '确认删除？';
      } else {
        title = '确认启用？';
      }
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let dt = {};
        if (row) {
          dt.id = row.id;
          dt.type = type;
        }
        // del(dt).then(res => {
        //     if (type == 1) {
        //         this.$message.success('停用成功');
        //     } else if (type == 2) {
        //         this.$message.success('删除成功');
        //     } else {
        //         this.$message.success('启用成功');
        //     }
        //     this.init();
        // });
      });
    },
    formatDate(value) {
      if (value) {
        let date = new Date(parseInt(value * 1000));
        let Y = date.getFullYear() + '-';
        let M =
          date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
        let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
        let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
        let m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
        let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return Y + M + D + h + m + s;
      }
    },
    indexMethod(index) {
      return this.page.pageSize * (this.page.page - 1) + 1 + index;
    },
    indexMethod1(index) {
      return this.page2.pageSize * (this.page2.page - 1) + 1 + index;
    },
    handleSizeChange(size) {
      this.page.pageSize = size;
      this.init();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.init();
    },
    handleSizeChange1(size) {
      this.page1.pageSize = size;
      this.addDepart();
    },
    handleCurrentChange1(page) {
      this.page1.page = page;
      this.addDepart();
    },
    init() {
      let dt = {
        page: this.page.page,
        limit: this.page.pageSize,
        id: this.associationId,
      };
      associationcomponentlist(dt).then((res) => {
        // console.log(res);
        this.tableData = res.result.data;
        // this.setupBelonging_to = res.result.setupBelonging_to;
        // this.tableData = res.result.list.data;
        // let { Add: add, Delete: del, Edit: edit, Enable: enable } = res.result.Power;
        // this.permissionsAction = {
        //     add,
        //     del,
        //     edit,
        //     enable
        // };
        this.page.totalItems = res.result.total;
      });
    },
  },
};
</script>
<style>
.case {
  /* padding-top: 20px; */
  padding: 30px;
}
.demo-input-label {
  display: inline-block;
  width: 130px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-tag + .el-tag {
  margin-left: 0px;
}
.el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
}
.button-new-tag {
  /* margin-left: 10px; */
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.mineBtn .el-button--small {
  padding: 9px 10px;
  line-height: 0px;
}
.input-new-tag {
  width: 90px;
}
</style>
