import $http from '@/utils/http.js';
/**
 * 案例关联列表
 * @param data
 * @returns
 */
export function associationcomponentlist(data) {
  return $http.get('/api/casemanage/associationcomponentlist', data, 'case');
}
/**
 *
 * 保存部件标签
 * @param  data
 * @returns
 */
export function saveADepart(data) {
  return $http.post('/api/casemanage/updateassociationlabel', data, 'case');
}
/**
 * 部件列表
 * @param  data
 * @returns
 */
export function componentlist(data) {
  return $http.get('/api/casemanage/componentlist', data, 'case');
}
/**
 * 部件关联列表
 * @param  data
 * @returns
 */
export function departLabel(data) {
  return $http.get('/api/casemanage/associationlabellist', data, 'case');
}
/**
 * 查询案例详情
 * @param data
 * @returns
 */
export function detailDepart(data) {
  return $http.post('/api/casemanage/case_info', data, 'case');
}
/**
 * 修改排序
 * @param data
 * @returns
 */
export function changeSort(data) {
  return $http.post('/api/casemanage/updateassociationsort', data, 'case');
}
/**
 * 新增部件
 * @param data
 * @returns
 */
export function submitDepart(data) {
  return $http.post('/api/casemanage/addassociation', data, 'case');
}
/**
 * 案例列表
 * @param data
 */
export function getCaseList(data) {
  return $http.get('/api/casemanage/case_list', data, 'case');
}
/**
 * 启用停用
 * @param data
 * @returns
 */
export function enableCase(data) {
  return $http.post('/api/casemanage/case_satus', data, 'case');
}
/**
 * 删除
 * @param data
 * @returns
 */
export function delCase(data) {
  return $http.post('/api/casemanage/case_del', data, 'case');
}
/**
 * 案例添加
 * @param data
 */
export function addCase(data) {
  return $http.post('/api/casemanage/case_add', data, 'case', 'multipart/form-data');
}
/**
 * 获取案例风格
 * @param data
 * @returns
 */
export function getStyle(data) {
  return $http.get('/api/casemanage/case_choose_style', data, 'case');
}
/**
 * 编辑案例
 * @param data
 * @returns
 */
export function editDepart(data) {
  return $http.post('/api/casemanage/case_edit', data, 'case', 'multipart/form-data');
}

export function deleleDepart() {
  // return $http.post('/api/casemanage/case_edit', data, 'case', 'multipart/form-data');
}
/**
/**
 * 查询体系/分类/材料/品牌数据
 * @param data
 */
export function getAttributesData(data) {
  return $http.get('/api/attributes_list', data, 'loadingDiv');
}
